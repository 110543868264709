class QueueItem<T> {
  value: T;
  next: QueueItem<T> | null = null;

  constructor(value: T) {
    this.value = value;
    this.next = null;
  }
}

export class Queue<T> {
  head: QueueItem<T> | null = null;
  tail: QueueItem<T> | null = null;
  length = 0;

  constructor() {
    this.head = null;
    this.tail = null;
    this.length = 0;
  }

  // Добавление в конец
  enqueue(value: T) {
    const newNode = new QueueItem(value);
    if (!this.tail) {
      this.head = newNode;
      this.tail = newNode;
    } else {
      this.tail.next = newNode;
      this.tail = newNode;
    }
    this.length++;
  }
  // Удаление из начала
  dequeue() {
    if (!this.head) return null;

    const removedNode = this.head;
    this.head = this.head.next;

    if (!this.head) {
      this.tail = null;
    }

    this.length--;

    return removedNode.value;
  }

  // Метод для отображения в виде массива (для удобства)
  toArray() {
    const result = [];
    let currentNode = this.head;
    while (currentNode) {
      result.push(currentNode.value);
      currentNode = currentNode.next;
    }

    return result;
  }
}
