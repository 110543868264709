import { action, makeAutoObservable, observable } from 'mobx';

import { CounterFilter } from './filter.model';

import { RootStore } from '@/Providers/StoreProvider/root.store';
import { AUTH_TYPE, IUser } from '@/types';
import { TargetsEtalonsStore } from '@/stores/ReportTargetsEtalons';
import { PrivilegeCode } from '@/types/role';

const setCaching = (data: IUser) => {
  localStorage.setItem('user_data', JSON.stringify(data));
  localStorage.setItem('t4f_token', data.t4f_token);
  localStorage.setItem('card_token', data.card_token);
};

const getCaching = (): IUser => {
  try {
    const userJson = localStorage.getItem('user_data');

    return userJson ? (JSON.parse(userJson) as IUser) : initialUserData;
  } catch (error) {
    return initialUserData;
  }
};

const clearCache = () => {
  localStorage.removeItem('user_data');
  localStorage.removeItem('t4f_token');
};

const initialUserData: IUser = {
  roles: [],
  privileges: [],
  token_privileges: [],
  t4f_token: '',
  card_token: '',
  id: 0,
  username: '',
  auth_type: AUTH_TYPE.T4,
  auth: false,
  newPasswordRequired: false,
  firstname: '',
  lastname: '',
  middlename: '',
};

class UserStore {
  root: RootStore;

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
    this.userData = getCaching();
  }

  @observable userData = initialUserData;

  @action setUserData = (data: IUser) => {
    this.userData = data;
    setCaching(data);
  };

  @action setCardAuthData = (cardToken: string, tokenPrivileges: PrivilegeCode[]) => {
    this.userData = {
      ...this.userData,
      card_token: cardToken,
      token_privileges: tokenPrivileges,
      auth: true,
    };
    setCaching(this.userData);
  };

  @action logoutAndClearUserData = () => {
    TargetsEtalonsStore.reset();
    clearCache();
    this.root.clear();
    this.userData = { ...initialUserData, auth: false };
    this.root.queryRootStore.filterStore.setQueryFilter(CounterFilter.ALL);
    this.root.queryRootStore.filterStore.setSqFilter(CounterFilter.ALL);
  };
}

export default UserStore;
