import { packDate } from '@/utils/packDate';

const BIRTHDATETITLE = 'Дата рождения';
const BIRTHTITLES = ['ДЕНЬ РОЖДЕНИЯ', 'МЕСЯЦ РОЖДЕНИЯ', 'ГОД РОЖДЕНИЯ'];
export interface IFieldTitle {
  key: number;
  title: string;
  g?: boolean;
  hide?: boolean;
}
export function convertInterfaceToFieldsName<T extends object>(obj: T): IFieldTitle[] {
  const result: IFieldTitle[] = [];

  const keys = Object.keys(obj);
  const vs = Object.values(obj);

  keys.forEach((key, index) => {
    if (vs[index] !== null) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      result.push({ key: +`${key[0]}`, title: `${vs[index]}`, g: keys.length === 2 || undefined });
    }
  });

  const fDay = result.find((field) => field.title.toUpperCase() === BIRTHTITLES[0]);
  if (fDay) {
    fDay.hide = true;
    result.push({ key: fDay.key, title: BIRTHDATETITLE });
  }
  const fMonth = result.find((field) => field.title.toUpperCase() === BIRTHTITLES[1]);
  if (fMonth) {
    fMonth.hide = true;
    if (!fDay) {
      result.push({ key: fMonth.key, title: BIRTHDATETITLE });
    }
  }
  const fYear = result.find((field) => field.title.toUpperCase() === BIRTHTITLES[2]);
  if (fYear) {
    fYear.hide = true;
    if (!fDay && !fMonth) {
      result.push({ key: fYear.key, title: BIRTHDATETITLE });
    }
  }

  const elem = result.splice(6, 1);
  result.push(...elem);

  return result;
}

export function fillValuesAtFields<T extends object>(obj: T, titles: IFieldTitle[]): string[] {
  const result: string[] = [];

  const keys = Object.keys(obj);
  const vs = Object.values(obj);

  keys.forEach((key, index) => {
    const keynum = +`${key[0]}`;
    const ind = titles.findIndex((t) => t.key === keynum);
    if (ind >= 0 && vs[index] !== null) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      result[ind] = `${vs[index]}`;
    }
  });

  const indDate = titles.findIndex((t) => t.title === BIRTHDATETITLE);
  const indD = titles.findIndex((t) => t.title.toUpperCase() === BIRTHTITLES[0]);
  const indM = titles.findIndex((t) => t.title.toUpperCase() === BIRTHTITLES[1]);
  const indY = titles.findIndex((t) => t.title.toUpperCase() === BIRTHTITLES[2]);
  result[indDate] = packDate(
    indD < 0 ? undefined : result[indD],
    indM < 0 ? undefined : result[indM],
    indY < 0 ? undefined : result[indY]
  );

  result.forEach((r, index) => {
    if (r === undefined || r.length === 0) {
      result[index] = ' ';
    }
  });

  return result;
}
