import { Box, Typography } from '@mui/material';
import MainLogo from './common/MainLogo';
import { useTranslation } from 'react-i18next';
import { Link } from 'stc-ui-kit';
import { makeStyles } from '@/hooks/makeStyles';
import { useDataStore } from '@/Providers/StoreProvider';
import { appColors } from '@/Providers/ThemeProvider/colors';

const useStyles = makeStyles()(() => ({
  link: {
    fontSize: '12px !important',
  },
}));

const About = () => {
  const {
    constantsStore: { factorVersion },
  } = useDataStore();
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box sx={{ padding: '24px 16px 16px 24px', minWidth: 462, display: 'flex', gap: 2 }}>
      <MainLogo />
      <Box>
        <Typography variant='h5' fontWeight={700}>
          {t('appName')}
        </Typography>
        <Typography fontSize={'12px'} color={appColors.white400}>
          {`${t('version')}: `}
          {factorVersion}
        </Typography>

        <Box sx={{ my: 3 }}>
          <Typography fontSize={'14px'} fontWeight={500}>
            {t('appInfo')}
          </Typography>
        </Box>

        <Box>
          <Typography fontSize={'12px'}>{t('appCreatorInfo')}</Typography>
          <Link
            onClick={() => window.open('https://www.speechpro.ru', '_blank')}
            className={classes.link}
            data-testid=''
          >
            {t('siteLink')}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
