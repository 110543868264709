import { StatusCodes } from 'http-status-codes';
import { CallAPIType, APIResponseType, TosterOptionsType, ErrorType } from '@/types';
import UserStore from '@/stores/user.model';
import { authService } from './auth.service';
import { t } from 'i18next';
import { showErrorNotification } from '@/utils/notifications';
import SseStore from '@/stores/sse.store';
import { MediaDevicesStore } from '@/stores/WebRTC/MediaDevicesStore';

export const makeURL = (endpoint: string) => {
  const prefix = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';

  return `${prefix}/api/v1/${endpoint || ''}`;
};

export const makePlayerUrl = (endpoint: string) => {
  const prefix = process.env.REACT_APP_PLAYER_API_URL || '';

  return `${prefix}/wms-sa/api/v1/${endpoint || ''}`;
};

export const makePlayerWsUrl = (endpoint: string) => {
  const prefix = process.env.REACT_APP_PLAYER_WS_API_URL || '';

  return `${prefix}/wms-sa/ws/v1/${endpoint || ''}`;
};

export const getServerMediaFilePath = (filePath: string, withToken = true) => {
  const uri = encodeURIComponent(filePath);

  let token = '';
  if (withToken) {
    const userAuthToken = authService.getUserAuthToken();
    if (userAuthToken) {
      token = `&token=${userAuthToken}`;
    } else {
      const cardToken = authService.getCardToken();
      if (cardToken) {
        token = `&CardToken=${cardToken}`;
      }
    }
  }

  return `/api/v1/file/by-relative-uri?uri=${uri}${token}`;
};

export const setAuthHeader = (headers: Headers) => {
  const userStore = rootApiService.userStore;

  if (!userStore) return;

  const { t4f_token, card_token } = userStore.userData;

  if (t4f_token) {
    headers.set('Authorization', `Bearer ${t4f_token}`);
  } else if (card_token) {
    headers.set('CardToken', card_token);
  }
};

const ERROR_STATUSES = [
  StatusCodes.UNAUTHORIZED,
  StatusCodes.GATEWAY_TIMEOUT,
  StatusCodes.BAD_GATEWAY,
];

class RootAPI {
  userStore: UserStore | undefined;
  mediaDevicesStore: MediaDevicesStore | undefined;
  sseStore: SseStore | undefined;

  private errorSet = new Set<string>();

  init(userStore: UserStore, mediaDevicesStore: MediaDevicesStore, sseStore: SseStore) {
    this.userStore = userStore;
    this.mediaDevicesStore = mediaDevicesStore;
    this.sseStore = sseStore;
  }

  private showErrorNotification = (errorDescription: ErrorType, options?: TosterOptionsType) => {
    const errorKey = errorDescription.description || errorDescription.code;

    if (this.errorSet.has(errorKey)) return;

    this.errorSet.add(errorKey);

    if (options) {
      const msgParts = [options.message];

      if (errorDescription.code) {
        msgParts.push(`code: ${errorDescription.code}`);
      }
      if (errorDescription.description) {
        msgParts.push(`description: '${errorDescription.description}'`);
      }

      showErrorNotification({ header: msgParts.join(' ') });
    } else {
      showErrorNotification({
        header: errorDescription.code,
        message: errorDescription.description || '',
      });
    }

    setTimeout(() => {
      this.errorSet.delete(errorKey);
    }, 5000);
  };

  callAPI = async <T>({
    path,
    tosterOptions,
    method,
    headers,
    body: bodyProp,
    ignoreBody,
    ignoreContentType,
    shouldShowErrorNotification = () => true,
  }: CallAPIType) => {
    const result: APIResponseType<T> = { statusCode: null, errorDescription: null, res: null };
    const apiCallHeaders = new Headers();
    setAuthHeader(apiCallHeaders);
    headers?.forEach((v, k) => apiCallHeaders.set(k, v));

    if (!apiCallHeaders?.has('Content-Type') && !ignoreContentType) {
      apiCallHeaders?.set('Content-Type', 'application/json');
    }

    let body: BodyInit | null | undefined = bodyProp;

    if (apiCallHeaders?.get('Content-Type') === 'application/json' && body) {
      body = JSON.stringify(bodyProp);
    }

    const apiRequest = new Request(makeURL(path), {
      method: method,
      headers: apiCallHeaders,
      body: body,
    });

    try {
      const response = await fetch(apiRequest);
      result.statusCode = response.status;
      const isError = this.checkStatus(path, response);

      if (isError) return result;

      const string = await response.text();
      if (string === '') return result;
      const json = JSON.parse(string);

      if (response.ok) {
        if (!ignoreBody) {
          result.res = json;
        }
        if (json?.error) {
          const { error } = json;
          result.errorDescription = error;
        }
      } else {
        if (json?.error) {
          const { error } = json;
          result.errorDescription = error;
        } else if (json?.code) {
          result.errorDescription = json;
        } else {
          result.errorDescription = {
            code: response.status.toString(),
            description: json?.description,
          };
        }

        if (
          !path.endsWith('/token/login') &&
          result.errorDescription &&
          !result.errorDescription.details &&
          shouldShowErrorNotification({ ...result.errorDescription, statusCode: result.statusCode })
        ) {
          this.showErrorNotification(result.errorDescription, tosterOptions);
        }

        result.res = null;
      }

      return result;
    } catch (error) {
      console.error(error);
    }

    return result;
  };

  checkStatus = (requestPath: string, response: Response): boolean => {
    const status = response.status;

    if (!this.userStore) return false;
    if (!ERROR_STATUSES.includes(status)) return false;
    if (status === StatusCodes.UNAUTHORIZED && requestPath.endsWith('/token/login')) {
      return false;
    }

    this.userStore?.logoutAndClearUserData();
    this.mediaDevicesStore?.clearStorageRecordingState();

    if (status === StatusCodes.GATEWAY_TIMEOUT || status === StatusCodes.BAD_GATEWAY) {
      this.showErrorNotification({ code: t('messages.serverIsNotResponding'), description: '' });
    }

    return true;
  };
}

const rootApiService = new RootAPI();
export default rootApiService;
