import { IFileLink } from '@/types/file';

export const MAX_AUDIO_RECORD_SECONDS = 3599; // 59 минут 59 секунд
// export const MAX_AUDIO_RECORD_SECONDS = 5; // for debug
export const AUTO_MODE_PHOTO_INTERVAL = 3000;
export const RECORD_SESSION_ID_FIELD: keyof IFileLink = 'recordSessionId';
export const INDICATE_ERROR_MIN_FRAMES = 10;
export const INDICATE_ERROR_FRAMES_PERCENT = 0.95;
export const PING_MODIFICATOR = 0.1;
export const BIOSCANNER_SESSION_QUERY = 'recordSessionId';
