import { Fragment, useState } from 'react';
import userService from '@/services/user.service';
import { Button, ButtonDropdown, ButtonStyle, ButtonType, icons } from 'stc-ui-kit';
import { Box, Dialog, Typography } from '@mui/material';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import qrCodeFrame from 'assets/icons/qrCodeFrame.svg';
import { useDataStore } from '@/Providers/StoreProvider';
import { appColors } from '@/Providers/ThemeProvider/colors';

export function QrCodeButton() {
  const [isQrCodeModalOpen, setisQrCodeModalOpen] = useState(false);
  const [qrCodeValue, setQrCodeValue] = useState('');
  const { t } = useTranslation();
  const { userStore, constantsStore } = useDataStore();
  const { userData } = userStore;

  const showQrCode = async (regenerate = false) => {
    setisQrCodeModalOpen(true);

    try {
      let bioscannerToken = '';

      if (!regenerate) {
        const currentToken = await userService.getBioscannerToken();
        bioscannerToken = currentToken.token;
      }

      if (!bioscannerToken) {
        const generatedToken = await userService.generateBioscannerToken();
        bioscannerToken = generatedToken.token;
      }
      const url = window.location.origin;
      const apiUrl =
        constantsStore.bioscannerApiUrl || (await constantsStore.initConstBioscannerApiUrl()) || '';

      setQrCodeValue(`url=${url};api=${apiUrl};token=${bioscannerToken}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <ButtonDropdown
        data-testid=''
        onMainButtonClick={() => showQrCode()}
        icon={icons.IconQr}
        buttonStyle={ButtonStyle.Secondary}
        content={
          <Button onClick={() => showQrCode(true)} type={ButtonType.Text}>
            {t('refresh')}
          </Button>
        }
      />

      <Dialog open={isQrCodeModalOpen} onClose={() => setisQrCodeModalOpen(false)} maxWidth={false}>
        <Box
          sx={{
            width: '684px',
            aspectRatio: '1 / 1',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography fontSize={'24px'} fontWeight={500} sx={{ marginBottom: 3, marginTop: 6 }}>
            {t('saveToStartWithBioscanner')}
          </Typography>
          <Box
            position={'relative'}
            sx={{
              background: `url(${qrCodeFrame}) no-repeat center`,
              width: '484px',
              height: '484px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ paddingBlock: '37px', paddingInline: '40px', backgroundColor: 'white' }}>
              <QRCode size={365} value={qrCodeValue} />
            </Box>
          </Box>

          <Typography fontSize={16} fontWeight={500} sx={{ marginTop: '30px' }}>
            {`${userData.lastname} ${userData.firstname} ${userData.middlename || ''}`}
          </Typography>
        </Box>
      </Dialog>
    </Fragment>
  );
}
