import { cloneDeep } from 'lodash';
import { IApiFieldFilter, IFilterDto } from '@/types';
import {
  IDataGridStoreData,
  IDataGridStoreState,
  IGridColumnFilter,
  IGridFilter,
} from 'types/grid';
import { ConditionType } from 'stc-ui-kit';
import { ICardsGridItem } from '@/types/card';
import { TSystemField } from '@/types/cardTemplate';
import { Predicate } from '@/types/predicate';
import { HasNextPageResponseDto } from '@/services/entityApi.service';

export const prepareFiltersRequestBody = <T>({
  filters,
  body,
}: {
  filters: IDataGridStoreState<T>;
  body: IFilterDto<T>;
}): IFilterDto<T> => {
  const preparedBody = cloneDeep(body);

  if (preparedBody.jsonFields) {
    return {
      limit: preparedBody.limit,
      offset: preparedBody.offset,
      operator: 'Or',
      jsonFields: preparedBody.jsonFields,
    };
  }

  if (filters.paginationType === 'offset') {
    preparedBody.offset = filters.offset;

    return preparedBody;
  }

  if (filters.paginationType === 'cursor') {
    preparedBody.after = filters.after;
    preparedBody.before = filters.before;

    return preparedBody;
  }

  throw new Error(`Pagination ${filters.paginationType as string} not implemented`);
};

export const prepareSideFiltersRequestBody = <T>({
  filters,
  body,
}: {
  filters: IDataGridStoreState<T>;
  body: IFilterDto<T>;
}): IFilterDto<T> => {
  const preparedBody = cloneDeep(body);

  if (preparedBody.jsonFields || preparedBody.fields) {
    return {
      limit: preparedBody.limit,
      offset: preparedBody.offset,
      operator: 'And',
      jsonFields: preparedBody.jsonFields,
      fields: preparedBody.fields,
      sortByFields: preparedBody.sortByFields,
    };
  }

  if (filters.paginationType === 'offset') {
    preparedBody.offset = filters.offset;

    return preparedBody;
  }

  if (filters.paginationType === 'cursor') {
    preparedBody.after = filters.after;
    preparedBody.before = filters.before;

    return preparedBody;
  }

  throw new Error(`Pagination ${filters.paginationType as string} not implemented`);
};

export const prepareFiltersResponseData = <T>({
  filters,
  data,
}: {
  filters: IDataGridStoreState<T>;
  data: T[] | HasNextPageResponseDto<T>;
}): IDataGridStoreData<T> => {
  if (Array.isArray(data)) {
    let sliceDataFrom = 0;

    if (filters.paginationType === 'cursor' && filters.before && data.length > filters.limit) {
      sliceDataFrom = 1;
    }

    return {
      data: data.slice(sliceDataFrom, filters.limit + sliceDataFrom),
      hasNextPage: data.length - filters.limit > 0,
    };
  }

  return {
    data: data.dtos,
    hasNextPage: data.hasNext,
  };
};

export function formatColumnFilters<T>(columnFilters: IGridColumnFilter<T>[]): IApiFieldFilter[] {
  return columnFilters.flatMap((f) => {
    if (!f.targetValues || f.targetValues.length === 0) {
      return [];
    }

    return {
      fieldId: String(f.key),
      predicate: f.predicate,
      targetValues: f.targetValues,
    };
  });
}

export function formatFilters<T>(quickFilters?: IGridFilter<T>[]): IApiFieldFilter[] {
  if (!quickFilters) return [];

  return Object.values(quickFilters)
    .flat()
    .map((f: IGridFilter<T>) => {
      const result: IApiFieldFilter = {
        fieldId: String(f.fieldId),
        predicate: f.predicate,
      };

      if (f.targetValues) {
        result.targetValues = f.targetValues;
      }

      return result;
    });
}

export function convertUiKitConditionTypeToPredicate(uiKitPredicate: ConditionType): Predicate {
  switch (uiKitPredicate) {
    case ConditionType.Contains:
      return Predicate.CONTAINS;
    case ConditionType.EndsWith:
      return Predicate.ENDS_WITH;
    case ConditionType.Equals:
      return Predicate.EQ;
    case ConditionType.Greater:
      return Predicate.GR;
    case ConditionType.GreaterOrEquals:
      return Predicate.EQ_GR;
    case ConditionType.Less:
      return Predicate.LESS;
    case ConditionType.LessOrEquals:
      return Predicate.EQ_LESS;
    case ConditionType.NotContains:
      return Predicate.NOT_CONTAINS;
    case ConditionType.NotEndsWith:
      return Predicate.NOT_ENDS_WITH;
    case ConditionType.NotEquals:
      return Predicate.NOT_EQ;
    case ConditionType.NotStartsWith:
      return Predicate.NOT_STARTS_WITH;
    case ConditionType.StartsWith:
      return Predicate.STARTS_WITH;
    default:
      return Predicate.EQ;
  }
}

function firstLetterToLowerCase(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function convertTSystemFieldToICardsGridItem(
  systemField: TSystemField
): keyof ICardsGridItem {
  return firstLetterToLowerCase(systemField) as keyof ICardsGridItem;
}
