import { createTheme } from '@mui/material/styles';

import { buttonStyles, defaultButtonStyles } from './styles/button';
import { chipStyles, defaultChipStyles } from './styles/chip';
import { defaultRadioStyles, radioStyles } from './styles/radio';
import { defaultLinerStyles, linerStyles } from './styles/linerProgress';
import { defaultTabStyles } from './styles/tab';
import { defaultTableHeadStyles } from './styles/tableHead';
import { defaultToolbarStyles, toolbarStyles } from './styles/toolbar';
import { COLOR } from './types';
import { defaultIconStyles, iconStyles } from './styles/icon';
import { defaultInputStyles } from './styles/input';

import { appColors } from '@/Providers/ThemeProvider/colors';
import { CSSProperties } from 'react';
import { Color } from 'stc-ui-kit/types/common';

const appTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      // main: appColors.mainBackground,
      // dark: appColors.primary,
      // light: '#D3D3D3',
      main: 'rgb(0, 108, 122)',
    },
    secondary: {
      main: '#ffffff33',

      light: '#ffffff55',
    },
    success: {
      main: appColors.success,
    },
    error: { main: appColors.error },
    info: { main: '#0CAA55' },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          // color: '#000000E0',
        },
      },
      defaultProps: {
        // color: '#000000E0',
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: appColors.darkThemeBackground,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {},
        head: {
          fontWeight: '550',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ ownerState: { color = 'default' } }) => {
          if (!toolbarStyles[color]) {
            return defaultToolbarStyles;
          }

          return {
            ...defaultToolbarStyles,
            ...toolbarStyles[color],
          };
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        vertical: {
          '&.MuiDivider-middle': {
            height: '2em',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: defaultTabStyles,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
          backgroundColor: appColors.black900,
        },
        arrow: {
          '&::before': {
            backgroundColor: appColors.black900,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: appColors.indigo600,
          '&.Mui-disabled': {
            pointerEvents: 'unset',
            cursor: 'default',
            color: appColors.steal300,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState: { color = 'default', variant = 'outlined' } }) => {
          return defaultButtonStyles;
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: defaultTableHeadStyles,
      },
    },

    MuiChip: {
      styleOverrides: {
        root: ({ ownerState: { color = 'default', variant = 'default' } }) => {
          if (!chipStyles[variant]?.[color]) {
            return defaultChipStyles;
          }

          return {
            ...defaultChipStyles,
            ...chipStyles[variant][color],
          };
        },
      },
    },

    MuiBadge: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === COLOR.SECONDARY && {
            color: appColors.red,
          }),
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: ({ ownerState: { color = 'primary' } }) => {
          if (!iconStyles[color]) {
            return defaultIconStyles;
          }

          return {
            ...defaultIconStyles,
            ...iconStyles[color],
          };
        },
      },
    },

    MuiSlider: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === COLOR.PRIMARY && {
            color: appColors.appToolbarBackgroundColor,
          }),
        }),
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: ({ ownerState: { color = 'primary' } }) => {
          if (!toolbarStyles[color]) {
            return defaultToolbarStyles;
          }

          return {
            ...defaultToolbarStyles,
            ...toolbarStyles[color],
          };
        },
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: ({ ownerState: { color = 'primary' } }) => {
          if (!radioStyles[color]) {
            return defaultRadioStyles;
          }

          return {
            ...defaultRadioStyles,
            ...radioStyles[color],
          };
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: ({ ownerState: { color = 'primary' } }) => {
          if (!linerStyles[color]) {
            return defaultLinerStyles;
          }

          return {
            ...defaultLinerStyles,
            ...linerStyles[color],
          };
        },
      },
    },

    MuiPagination: {
      styleOverrides: {
        root: {
          '& :hover': {
            color: '#fff',
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          ...defaultInputStyles,
        },
        input: {
          padding: '10px 8px !important',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '16px',
        },
        multiline: {
          padding: '0px 0px 4px 0px !important',
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: appColors.bgPrimary,
          },
        },
      },
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: ({ ownerState: { max } }) => ({
          ...[...Array(max)].reduce(
            (result: CSSProperties, _, index) => ({
              ...result,
              [`& > .MuiAvatar-root:nth-of-type(${index + 1})`]: {
                zIndex: (max as number) - index,
              },
            }),
            {}
          ),
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '12px 16px',
          fontSize: '15px',
          fontWeight: 500,
          '&:hover': {
            backgroundColor: appColors.grey100,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(205, 205, 205, 1)',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          padding: '8px 8px 8px 0',
          fontSize: 12,
          fontWeight: 500,
        },
        asterisk: {
          color: '#ff8882',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          background: '#1f1f22',
        },
      },
    },
  },
});

export default appTheme;
