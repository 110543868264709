export interface IRoleWithPrivs {
  id: number;
  roleName: string;
  privileges: PrivilegeCode[];
  isSystem: boolean | null;
}

export interface IPrivilegeGroup {
  name: string;
  privileges: PrivilegeCode[];
}

export interface IRoleWithPrivilegesInGroups {
  id: number;
  roleName: string;
  isSystem: boolean | null;
  privilegeGroups: IPrivilegeGroup[];
}

export interface IPrivilegeSection {
  name: string;
  privilegeGroups: IPrivilegeGroup[];
}

export interface ICreateRoleDto {
  roleName: string;
  privileges: PrivilegeCode[];
}

export enum PrivilegeCode {
  VIEW_USERS = 'VIEW_USERS',
  CREATE_USER = 'CREATE_USER',
  EDIT_USER = 'EDIT_USER',
  LOCK_UNLOCK_USER = 'LOCK_UNLOCK_USER',

  VIEW_GROUPS = 'VIEW_GROUPS',
  CREATE_GROUPS = 'CREATE_GROUPS',
  EDIT_GROUPS = 'EDIT_GROUPS',
  DELETE_GROUPS = 'DELETE_GROUPS',
  USER_GROUP_AFFILIATION = 'USER_GROUP_AFFILIATION',

  VIEW_ROLES = 'VIEW_ROLES',
  CREATE_ROLES = 'CREATE_ROLES',
  EDIT_ROLES = 'EDIT_ROLES',
  DELETE_ROLES = 'DELETE_ROLES',

  VIEW_CARDS = 'VIEW_CARDS',
  VIEW_ALL_CARDS = 'VIEW_ALL_CARDS',
  CREATE_CARDS = 'CREATE_CARDS',
  EDIT_CARDS = 'EDIT_CARDS',
  DELETE_CARDS = 'DELETE_CARDS',
  EXPORT_CARD_FILES = 'EXPORT_CARD_FILES',
  EXPORT_CARDS = 'EXPORT_CARDS',
  ADD_CARD_FILE = 'ADD_CARD_FILE',
  DELETE_CARD_FILE = 'DELETE_CARD_FILE',

  ACCESS_TO_DEVICES = 'ACCESS_TO_DEVICES',
}
