import { PrivilegeCode } from '@/types/role';

export type PermissionCondition = PrivilegeCode[][];

export interface PermissionControl {
  [key: string]: PermissionCondition | PermissionControl;
}

// Подмассивы внутри PrivilegeCode[][] соединяются по ИЛИ, а элементы внутри подмассива по И.

export const permissionControl = {
  administration: {
    page: [
      [PrivilegeCode.LOCK_UNLOCK_USER],
      [PrivilegeCode.EDIT_USER],
      [PrivilegeCode.VIEW_USERS],
      [PrivilegeCode.CREATE_USER],
      [PrivilegeCode.USER_GROUP_AFFILIATION],
      [PrivilegeCode.VIEW_GROUPS],
      [PrivilegeCode.EDIT_GROUPS],
      [PrivilegeCode.CREATE_GROUPS],
      [PrivilegeCode.DELETE_GROUPS],
      [PrivilegeCode.VIEW_ROLES],
      [PrivilegeCode.CREATE_ROLES],
      [PrivilegeCode.DELETE_ROLES],
      [PrivilegeCode.EDIT_ROLES],
    ],
    users: {
      page: [
        [PrivilegeCode.LOCK_UNLOCK_USER],
        [PrivilegeCode.EDIT_USER],
        [PrivilegeCode.VIEW_USERS],
        [PrivilegeCode.CREATE_USER],
      ],
      createButton: [[PrivilegeCode.CREATE_USER]],
      editButton: [
        [PrivilegeCode.LOCK_UNLOCK_USER],
        [PrivilegeCode.EDIT_USER],
        [PrivilegeCode.VIEW_USERS],
        [PrivilegeCode.CREATE_USER],
        [PrivilegeCode.USER_GROUP_AFFILIATION],
      ],
      changePasswordButton: [[PrivilegeCode.EDIT_USER]],
      blockButton: [[PrivilegeCode.LOCK_UNLOCK_USER]],
      unblockButton: [[PrivilegeCode.LOCK_UNLOCK_USER]],
      createConfirmButton: [[PrivilegeCode.CREATE_USER]],
      editConfirmButton: [[PrivilegeCode.EDIT_USER]],
    },
    roles: {
      page: [
        [PrivilegeCode.VIEW_ROLES],
        [PrivilegeCode.CREATE_ROLES],
        [PrivilegeCode.DELETE_ROLES],
        [PrivilegeCode.EDIT_ROLES],
      ],
      createButton: [[PrivilegeCode.CREATE_ROLES]],
      editButton: [[PrivilegeCode.EDIT_ROLES], [PrivilegeCode.CREATE_ROLES]],
      deleteButton: [[PrivilegeCode.DELETE_ROLES]],
      rolesManagmentButton: [[PrivilegeCode.EDIT_ROLES], [PrivilegeCode.CREATE_ROLES]],
      saveRolesManagmentButton: [[PrivilegeCode.EDIT_ROLES]],
      createConfirmButton: [[PrivilegeCode.CREATE_ROLES]],
      editConfirmButton: [[PrivilegeCode.EDIT_ROLES]],
    },
    groups: {
      page: [
        [PrivilegeCode.USER_GROUP_AFFILIATION],
        [PrivilegeCode.VIEW_GROUPS],
        [PrivilegeCode.EDIT_GROUPS],
        [PrivilegeCode.CREATE_GROUPS],
        [PrivilegeCode.DELETE_GROUPS],
      ],
      createButton: [[PrivilegeCode.CREATE_GROUPS]],
      editButton: [[PrivilegeCode.EDIT_GROUPS]],
      deleteButton: [[PrivilegeCode.DELETE_GROUPS]],
      addUserButton: [[PrivilegeCode.USER_GROUP_AFFILIATION]],
      excludeButton: [[PrivilegeCode.USER_GROUP_AFFILIATION]],
      addUserConfirmButton: [[PrivilegeCode.USER_GROUP_AFFILIATION]],
      createConfirmButton: [[PrivilegeCode.CREATE_GROUPS]],
      editConfirmButton: [[PrivilegeCode.EDIT_GROUPS]],
    },
  },
  enrollment: {
    page: [
      [PrivilegeCode.EDIT_CARDS],
      [PrivilegeCode.VIEW_ALL_CARDS],
      [PrivilegeCode.DELETE_CARDS],
      [PrivilegeCode.CREATE_CARDS],
      [PrivilegeCode.EXPORT_CARDS],
      [PrivilegeCode.VIEW_CARDS],
      [PrivilegeCode.EXPORT_CARD_FILES],
    ],
    fillingCabinet: {
      page: [
        [PrivilegeCode.EDIT_CARDS],
        [PrivilegeCode.VIEW_ALL_CARDS],
        [PrivilegeCode.DELETE_CARDS],
        [PrivilegeCode.CREATE_CARDS],
        [PrivilegeCode.EXPORT_CARDS],
        [PrivilegeCode.VIEW_CARDS],
        [PrivilegeCode.EXPORT_CARD_FILES],
      ],
      addButton: [[PrivilegeCode.EDIT_CARDS], [PrivilegeCode.CREATE_CARDS]],
      createEntryButton: [[PrivilegeCode.EDIT_CARDS], [PrivilegeCode.CREATE_CARDS]],
      deleteButton: [[PrivilegeCode.DELETE_CARDS]],
      exportButton: [[PrivilegeCode.EXPORT_CARDS]],
    },
    cardCreation: {
      editFields: [[PrivilegeCode.EDIT_CARDS], [PrivilegeCode.CREATE_CARDS]],
      saveButton: [[PrivilegeCode.EDIT_CARDS], [PrivilegeCode.CREATE_CARDS]],
      deleteButton: [[PrivilegeCode.DELETE_CARDS]],
      modelsSection: {
        uploadButton: [[PrivilegeCode.ADD_CARD_FILE]],
        recognizeButton: [[PrivilegeCode.EDIT_CARDS], [PrivilegeCode.CREATE_CARDS]],
        downloadButton: [[PrivilegeCode.EXPORT_CARD_FILES]],
        deleteButton: [[PrivilegeCode.DELETE_CARD_FILE]],
        setAsMainButton: [[PrivilegeCode.EDIT_CARDS], [PrivilegeCode.CREATE_CARDS]],
        createNewEntryButton: [[PrivilegeCode.CREATE_CARDS]],
        editFileInfoButton: [[PrivilegeCode.EDIT_CARDS], [PrivilegeCode.CREATE_CARDS]],
      },
    },
  },
  devices: {
    page: [[PrivilegeCode.ACCESS_TO_DEVICES]],
  },
};
