import { FC } from 'react';
import { Loader as StcLoader } from 'stc-ui-kit';
import { Typography } from '@mui/material';
import styles from './Loader.module.scss';

type Props = {
  text: string;
  isOpaque?: boolean;
};

export const Loader: FC<Props> = ({ text }) => {
  return (
    <div className={styles.progress}>
      <StcLoader data-testid='' className={styles.loader} />
      <Typography className={styles.text}>{text}</Typography>
    </div>
  );
};
