import { makeAutoObservable } from 'mobx';
import { ISystem, CONSTANTS, SnackbarActionType, Constants, AudioProcessing } from '@/types';
import { constantsService } from '@/services/constants.service';
import RecordSessionConstants from './recordSessionConstants.store';

class ConstantsStore {
  constructor() {
    makeAutoObservable(this);
  }

  constFileMaxCount = 0;
  constFileMaxSize = 0;
  constImageThreshold = 0;
  constImageMinThreshold = 0;
  constSoundThreshold = 0;
  constSoundMinThreshold = 0;
  constExtSystemList: ISystem[] = [];
  constDisplaySetsTab = false;
  isInitialized = false;
  isAllLoaded = false;
  isSideNavCollapsed = false;
  bioscannerApiUrl = '';
  loadFromExternal: boolean | undefined = undefined;
  audioProcessingType: AudioProcessing | undefined = undefined;
  factorVersion = '';

  recordSessionConstants = new RecordSessionConstants();

  private snackbarAction?: SnackbarActionType;

  initConstFactorVersion = async () => {
    const value = await constantsService.getConstantValue(CONSTANTS.FACTOR_VERSION);

    if (value) {
      this.setConstFactorVersion(value as string);

      return value as string;
    }
  };

  setConstFactorVersion = (value: string) => {
    this.factorVersion = value;
  };

  setConstFileMaxCount = (value: number) => {
    this.constFileMaxCount = value;
  };

  setIsSideNavCollapsed = (value: boolean) => {
    this.isSideNavCollapsed = value;
  };

  initConstAudioProcessingType = async () => {
    const value = await constantsService.getConstantValue(CONSTANTS.AUDIO_PROCESSING_TYPE);

    if (value) {
      this.setConstAudioProcessingType(value as AudioProcessing);

      return value as AudioProcessing;
    }
  };

  setConstAudioProcessingType = (value: AudioProcessing | undefined) => {
    this.audioProcessingType = value;
  };

  initConstLoadFromExternal = async () => {
    const value = await constantsService.getConstantValue(CONSTANTS.LOAD_FROM_EXTERNAL);

    if (value) {
      this.setConstLoadFromExternal(value as boolean);

      return value as boolean;
    }
  };

  setConstLoadFromExternal = (value: boolean | undefined) => {
    this.loadFromExternal = value;
  };

  initConstBioscannerApiUrl = async () => {
    const value = await constantsService.getConstantValue(CONSTANTS.BIOSCANNER_API_URL);

    if (value) {
      this.setConstBioscannerApiUrl(value as string);

      return value as string;
    }
  };

  setConstBioscannerApiUrl = (value: string) => {
    this.bioscannerApiUrl = value;
  };

  initConstFileMaxCountApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue(CONSTANTS.ATTACHED_MAX_COUNT);
      this.setConstFileMaxCount(value as number);

      return value as number;
    }

    return this.constFileMaxCount;
  };

  setConstFileMaxSize = (value: number) => {
    this.constFileMaxSize = value;
  };

  initConstFileMaxSizeApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue(CONSTANTS.ATTACHED_MAX_SIZE);
      this.setConstFileMaxSize(value as number);

      return value as number;
    }

    return this.constFileMaxSize;
  };

  setConstImageThreshold = (value: number) => {
    this.constImageThreshold = value;
  };

  initConstImageThresholdApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue(CONSTANTS.FACE_IDENT_THRESHOLD);
      this.setConstImageThreshold(value as number);

      return value as number;
    }

    return this.constImageThreshold;
  };

  setConstImageMinThreshold = (value: number) => {
    this.constImageMinThreshold = value;
  };

  initConstImageMinThresholdApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue(CONSTANTS.FACE_MIN_IDENT_THRESHOLD);
      this.setConstImageMinThreshold(value as number);

      return value as number;
    }

    return this.constImageMinThreshold;
  };

  setConstSoundThreshold = (value: number) => {
    this.constSoundThreshold = value;
  };

  initConstSoundThresholdApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue(CONSTANTS.VOICE_IDENT_THRESHOLD);
      this.setConstSoundThreshold(value as number);

      return value as number;
    }

    return this.constSoundThreshold;
  };

  setConstSoundMinThreshold = (value: number) => {
    this.constSoundMinThreshold = value;
  };

  initConstSoundMinThresholdApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue(CONSTANTS.VOICE_MIN_IDENT_THRESHOLD);
      this.setConstSoundMinThreshold(value as number);

      return value as number;
    }

    return this.constSoundMinThreshold;
  };

  setConstExtSystemList = (value: ISystem[]) => {
    this.constExtSystemList = value;
  };

  initConstExtSystemListApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue<ISystem[]>(CONSTANTS.EX_SYSTEM);
      this.setConstExtSystemList(value || []);

      return value;
    }

    return this.constExtSystemList;
  };

  setConstDisplaySetsTab = (value: boolean) => {
    this.constDisplaySetsTab = value;
  };

  initConstDisplaySetsTabApiValue = async () => {
    if (this.snackbarAction) {
      const value = await constantsService.getConstantValue<boolean>(CONSTANTS.DISPLAY_SETS_TAB);
      this.setConstDisplaySetsTab(value || true);

      return value;
    }

    return this.constDisplaySetsTab;
  };

  setInitialized = (state: boolean) => {
    this.isInitialized = state;
  };

  setAllLoaded = (state: boolean) => {
    this.isAllLoaded = state;
  };

  init = (snackbarAction: SnackbarActionType) => {
    if (snackbarAction) {
      this.snackbarAction = snackbarAction;
      this.setInitialized(true);
    }
  };

  loadAllConstantsValues = async () => {
    try {
      const fileMaxCount = await this.initConstFileMaxCountApiValue();
      const fileMaxSize = await this.initConstFileMaxSizeApiValue();
      const imageThreshold = await this.initConstImageThresholdApiValue();
      const imageMinThreshold = await this.initConstImageMinThresholdApiValue();
      const soundThreshold = await this.initConstSoundThresholdApiValue();
      const soundMinThreshold = await this.initConstSoundMinThresholdApiValue();
      const extSystemList = await this.initConstExtSystemListApiValue();
      const displaySetsTab = await this.initConstDisplaySetsTabApiValue();
      this.setAllLoaded(true);

      const result: Constants = {
        constFileMaxCount: fileMaxCount,
        constFileMaxSize: fileMaxSize,
        constImageThreshold: imageThreshold,
        constImageMinThreshold: imageMinThreshold,
        constSoundThreshold: soundThreshold,
        constSoundMinThreshold: soundMinThreshold,
        constExtSystemList: extSystemList || [],
        constDisplaySetsTab: displaySetsTab || true,
      };

      return result;
    } catch (error) {
      throw error;
    }
  };

  clear = () => {
    this.setInitialized(false);
    this.setAllLoaded(false);
    this.setConstFileMaxCount(0);
    this.setConstFileMaxSize(0);
    this.setConstImageThreshold(0);
    this.setConstImageMinThreshold(0);
    this.setConstSoundThreshold(0);
    this.setConstSoundMinThreshold(0);
    this.setConstExtSystemList([]);
    this.setConstDisplaySetsTab(false);
    this.setConstBioscannerApiUrl('');
    this.setConstLoadFromExternal(undefined);
    this.setConstAudioProcessingType(undefined);
  };
}

export default ConstantsStore;
