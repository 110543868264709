import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useDataStore } from '@/Providers/StoreProvider';
import { ROUTES } from '@/types';
import userService from '@/services/user.service';
import { Loader } from '@/components/common/Loader';

type Props = {
  children: JSX.Element;
};

const RequireAuth: React.FC<Props> = ({ children }) => {
  const { userStore } = useDataStore();
  const isAuthenticated = userStore.userData.auth;
  const needNewPassword = userStore.userData.newPasswordRequired;
  const location = useLocation();
  const { id: cardId } = useParams();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const authenticateWithCardToken = async () => {
      const urlParams = new URLSearchParams(location.search);
      const cardToken = urlParams.get('CardToken');

      if (cardToken && cardId) {
        await userService.logInByCardToken(Number(cardId), cardToken, userStore);
      }

      setLoading(false);
    };

    void authenticateWithCardToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId, location.search]);

  if (loading) {
    return <Loader text='' />;
  }

  return isAuthenticated && !needNewPassword ? (
    children
  ) : (
    <Navigate to={ROUTES.AUTH} replace state={{ path: location.pathname, needNewPassword }} />
  );
};

export default RequireAuth;
