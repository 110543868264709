import { TDirType } from '@/components/FileDialog/types';
import { Model } from './model';
import { AudioProcessing } from '@/types';

export type FileType = 'audio' | 'img';

export enum EFileProcessStatus {
  INPROGRESS = 'IN_PROGRESS',
  NEW = 'NEW',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  DUPLICATE = 'DUPLICATE',
}

export interface IMedialFile {
  id: number;
  path: string;
  isExternal: boolean;
  size: number;
  type: FileType;
}

export interface IFileModelLink {
  id: number;
  isSelected: boolean | null;
  fileModel: Model;
}

type User = {
  id: number;
  isEnabled: boolean;
  firstname: string;
  lastname: string;
  middlename: string | null;
  username: string;
};

interface IMetadataItem {
  data: {
    channelCount: number;
    durationSec: number;
    sampleCount: number;
    sampleFormatName: string;
    sampleRate: number;
    totalTimeSpeech: number;
  };
  type: string;
}

export interface IFileLink {
  id: number;
  cardId: number;
  comment: string | null;
  fileGroup: FileGroupType;
  fileProcessId: number;
  filename: string;
  originalName: string | null;
  creator: User | null;
  createdTime: string;
  editor: User | null;
  editedTime: string | null;
  status: EFileProcessStatus;
  modelsCount: number | null;
  cardsCount: number;
  fileData: Record<string, string>;
  file: IMedialFile;
  fileModelLinks: IFileModelLink[] | null;
  path: string;
  metadata?: { metadataItems: IMetadataItem[] };
  recordSessionId: string | null;
  faceInteractiveMode: FaceInteractiveMode | null;
  errors: string | null;
}

export interface ITableItem extends Omit<IFileLink, 'id'> {
  id: string;
}

export type FileGroupType = 'FACE' | 'VOICE' | 'DOC' | null;

export interface IProcessPathRequest {
  processingParametersByFileType?: Record<
    string,
    { type: string; data: Record<string, boolean> }[]
  >;
  paths: { path: string; type: TDirType }[];
}

export interface IProcessFileRequest {
  processingParameters: {
    type: AudioProcessing;
    data: {
      sliceOnly: boolean;
      buildModels: boolean;
    };
  }[];
  file: File;
}

export interface IFileProcessUpdate {
  id: string;
  status: EFileProcessStatus;
}
export interface IModelUpdate {
  id: number;
  isExcluded: boolean;
}

export interface IFileModelView<T> {
  id: number;
  path: string;
  fileHash: string;
  isExcluded: boolean;
  cardsCount: string;
  modelQuality: number;
  data: T;
  sourcePath: string;
  type: FileType;
}

export interface IMediaData {
  snr?: number;
  rtRms?: number;
  ranges?: number[];
  rtAver?: number;
  quality?: number;
  rtCount?: number;
  algorithm?: string;
  timeSpeech?: number;
  clippingRanges?: number;
  timeTonalNoise?: number;
  lowestSpeechFreqHz?: number;
  highestSpeechFreqHz?: number;
  maxUnclippedInterval?: number;
}

export interface IFileProcessResponse {
  errors: {
    description: string;
  }[];
}

export enum FaceInteractiveMode {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}
