import { IApiJsonFieldFilter } from '@/types';
import { GridColDef } from '@mui/x-data-grid';
import { Predicate } from './predicate';

export enum EDataGridStoreSortMode {
  ASC = 'ASC',
  DSC = 'DESC',
}

export interface IGridColumnFilter<T> {
  key: keyof T;
  predicate: Predicate;
  targetValues: (string | null | boolean)[];
  ignoreCase?: boolean;
  score?: string;
  jsonFields?: IApiJsonFieldFilter[];
}

export interface IGridFilter<T> {
  fieldId: keyof T;
  targetValues?: (string | null | boolean)[];
  predicate: Predicate;
}

export type PaginatonType = 'cursor' | 'offset';

export interface IDataGridStoreState<T> {
  offset: number;
  after: T | null;
  before: T | null;
  limit: number;
  sort: EDataGridStoreSortMode;
  sortField: keyof T;
  paginationType: PaginatonType;
  regularFilters: IGridFilter<T>[]; // просто обычные фильтры, соединяются по И
  columnFilters: IGridColumnFilter<T>[]; // боковые фильтры с меняющимся предикатом
  fastFilters: IGridFilter<T>[]; // фильтры, соединяющиеся по ИЛИ, есть только в апи картотеки
  orFilters: IGridFilter<T>[]; // фильтры, соединяющиеся по ИЛИ, есть только в апи устройств
  searchString?: string;
}

export interface IDataGridStoreData<T> {
  data: T[];
  hasNextPage?: boolean;
}

export type DataProviderResult<T> = Promise<IDataGridStoreData<T>>;
export type DataProvider<T> = (
  state: IDataGridStoreState<T>,
  localData?: T[]
) => DataProviderResult<T>;

export type SseSubscriber = (itemIds: number[]) => Promise<void>;

export interface IDataGridStore<T> {
  data: T[];
  isPending: boolean;
  error?: string;
  state: IDataGridStoreState<T>;
  checkedItems: T[];
  rowsOnPage: number;
  currentPage: number;
  dataProvider: DataProvider<T>;
  hasNextPage: boolean;

  setCheckedItems: (items: T[]) => void;
  reload: () => Promise<void>;
  loadData: (state: IDataGridStoreState<T>) => Promise<void>;

  resetChecked: () => void;

  resetSort: () => void;

  setRowsOnPage: (number: number) => void;
  setCurrentPageAndLoadData: (number: number) => void;

  setDataProvider: (provider: DataProvider<T>) => void;
}

export interface IGridColDef<T extends object> extends GridColDef {
  field: Extract<keyof T, string> | 'edit' | 'delete';
}
