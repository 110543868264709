import { IConfig } from '@/types';

import { SearchReqestRootStore } from '@/components/searchRequestPage/searchRequestRoot.store';
import UserStore from '@/stores/user.model';
import QueryStore from '@/components/queryPage/query.store';
import ImageCompareRootStore from '@/stores/imageCompare/imageCompare.root.store';
import ConstantsStore from '@/stores/constants.model';
import { UiStore } from '@/stores/ui.store';
import SseStore from '@/stores/sse.store';
import { TemplatesStore } from '@/stores/templates.store';
import { MediaDevicesStore } from '@/stores/WebRTC/MediaDevicesStore';

export class RootStore {
  uiStore: UiStore;
  userStore: UserStore;
  constantsStore: ConstantsStore;
  queryRootStore: QueryStore; //?
  searchRequestRootStore: SearchReqestRootStore; //?
  compareImagesRootStore: ImageCompareRootStore; //?
  sseStore: SseStore;
  templatesStore: TemplatesStore;

  settings?: IConfig;
  rootMediaDevicesStore: MediaDevicesStore;

  constructor() {
    this.uiStore = new UiStore();
    this.userStore = new UserStore(this);
    this.constantsStore = new ConstantsStore();
    this.queryRootStore = new QueryStore();
    this.searchRequestRootStore = new SearchReqestRootStore();
    this.compareImagesRootStore = new ImageCompareRootStore();
    this.sseStore = new SseStore();
    this.templatesStore = new TemplatesStore();
    this.rootMediaDevicesStore = new MediaDevicesStore({
      recordSessionConstantsStore: this.constantsStore.recordSessionConstants,
    });
  }

  clear() {
    this.uiStore.clear();
    this.searchRequestRootStore.clear();
    this.compareImagesRootStore.clear();
    this.constantsStore.clear();
    this.queryRootStore.clear();
  }
}
