import { makeStyles } from '@/hooks/makeStyles';
import { useValidation } from '@/hooks/useValidation';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType, Form, PasswordField, icons, useForm } from 'stc-ui-kit';

export interface INewPasswordForm {
  password: string;
  confirmPassword: string;
}

const useStyles = makeStyles()(() => ({
  submitButton: {
    width: '100%',
    borderRadius: '2px',
    marginTop: '8px',
  },
  createNewPasswordText: {
    fontSize: '18px',
    fontWeight: 700,
    color: 'white',
    '@media (min-width: 600px)': {
      fontSize: '20px',
    },
    '@media (min-width: 1200px)': {
      fontSize: '28px',
    },
    '@media (min-width: 1800px)': {
      fontSize: '40px',
    },
  },
  field: {
    '& div': {
      color: 'rgba(255, 255, 255, 0.64) !important',
    },
  },
}));

interface Props {
  onSubmit: (values: INewPasswordForm) => Promise<void>;
  onBack: () => void;
}

export const NewPasswordForm: React.FC<Props> = ({ onSubmit, onBack }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const form = useForm<INewPasswordForm>();
  const { handleSubmit, watch, formState } = form;
  const { validatePassword, validatePasswordConfirm } = useValidation(watch('password'));

  const confirmPassword = watch('confirmPassword');
  const password = watch('password');
  const isSubmitDisabled =
    !password || !confirmPassword || Object.keys(formState.errors).length > 0;

  return (
    <>
      <Button type={ButtonType.Text} icon={icons.IconArrowLeft} onClick={onBack}>
        {t('back')}
      </Button>
      <Form form={form} data-testid='' onSubmit={onSubmit}>
        <Stack spacing={1}>
          <Typography className={classes.createNewPasswordText}>
            {t('auth.createNewPassword')}
          </Typography>

          <PasswordField
            id={'password'}
            label={t('auth.newPassword')}
            placeholder={t('auth.enterNewPassword')}
            isRequired
            className={classes.field}
            data-testid=''
            validate={validatePassword}
            isFocusOnLoad
          />

          <PasswordField
            id={'confirmPassword'}
            label={t('auth.newPasswordConfirmation')}
            placeholder={t('auth.confirmNewPassword')}
            className={classes.field}
            isRequired
            data-testid=''
            validate={validatePasswordConfirm}
          />

          <Button
            onClick={handleSubmit(onSubmit)}
            className={classes.submitButton}
            isDisabled={isSubmitDisabled}
          >
            {t('save')}
          </Button>
        </Stack>
      </Form>
    </>
  );
};
