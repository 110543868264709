import { Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDataStore } from '@/Providers/StoreProvider';
import { makeStyles } from '@/hooks/makeStyles';
import userService from '@/services/user.service';
import { AUTH_TYPE, IAuthProps, IUserToChangePassword } from '@/types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Weave } from 'assets/icons/weave.svg';
import { ReactComponent as Login } from 'assets/icons/login.svg';
import { Icon, IconSize, icons } from 'stc-ui-kit';
import { useCallback, useEffect, useRef, useState } from 'react';
import { INewPasswordForm, NewPasswordForm } from '@/components/Auth/NewPasswordForm';
import { AuthForm } from '@/components/Auth/AuthForm';
import { useUserPermissionsContext } from '@/hooks/useUserPermissionsContext';
import { showErrorNotification } from '@/utils/notifications';
import { Void } from '@/components/common/Void';
import { LanguageSelector } from '@/components/LanguageSelector';
import { observer } from 'mobx-react';
import { appColors } from '@/Providers/ThemeProvider/colors';

const useStyles = makeStyles()(() => ({
  weaveRight: {
    height: 'auto',
    width: '35vw',
    position: 'absolute',
    top: '-15vw',
    right: '-13vw',
  },
  root: {
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#2E343B',
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  authBox: {
    width: '62%',
    height: '60%',
    borderRadius: '16px',
    border: '1px solid rgba(255, 255, 255, 0.08)',
    boxShadow: '0 0 28px 0 rgba(0, 0, 0, 0.4)',
    backgroundColor: '#1A1F25',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 2,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  weaveInsideAuthBox: {
    width: '53vw',
    height: 'auto',
    transform: 'rotate(43deg)',
    position: 'absolute',
    top: '-18vh',
    left: '-13vw',
  },
  login: {
    width: '40%',
    height: 'auto',
    marginLeft: '7%',
    zIndex: 5,
  },
  weaveLeft: {
    height: 'auto',
    width: '53vw',
    position: 'absolute',
    top: '37px',
    left: '-4px',
  },
  appName: {
    fontSize: '10px',
    color: 'white',
    fontWeight: 700,
    '@media (min-width: 600px)': {
      fontSize: '12px',
    },
    '@media (min-width: 1200px)': {
      fontSize: '16px',
    },
    '@media (min-width: 1800px)': {
      fontSize: '20px',
    },
  },
}));

const AuthPage = observer(function AuthPage() {
  const { classes } = useStyles();
  const location = useLocation();
  const rootStore = useDataStore();
  const {
    userStore,
    constantsStore: { factorVersion },
  } = rootStore;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const { defaultRoute } = useUserPermissionsContext();
  const [showNewPasswordForm, setShowNewPasswordForm] = useState(location.state?.needNewPassword);
  const shouldNavigateAfterLogin = useRef(false);
  const [authErrorMessage, setAuthErrorMessage] = useState('');

  const handleLogin = async (authProps: IAuthProps) => {
    try {
      const data = await userService.logIn(rootStore, authProps);

      const isError = !data || ('code' in data && 'description' in data);

      if (isError) {
        if (data?.code === 'AUTH_ERROR') {
          setAuthErrorMessage(data.description);
        }

        return;
      }

      setAuthErrorMessage('');

      if (data.auth_type !== AUTH_TYPE.T4F) {
        showErrorNotification({ header: t('auth.youDontHavePermissions') });

        return;
      }

      if (data.newPasswordRequired) {
        setShowNewPasswordForm(true);
      } else {
        shouldNavigateAfterLogin.current = true;
      }
    } catch (error) {
      console.log('auth');
    }
  };

  const navigateAfterLogin = useCallback(() => {
    if (!defaultRoute) {
      showErrorNotification({ header: t('auth.youDontHavePermissions') });

      return;
    }

    navigate((state?.path as string) || defaultRoute);
  }, [defaultRoute, navigate, state?.path, t]);

  useEffect(() => {
    if (shouldNavigateAfterLogin.current) {
      shouldNavigateAfterLogin.current = false;
      navigateAfterLogin();
    }
  }, [navigateAfterLogin]);

  const handleChangePassword = async (values: INewPasswordForm) => {
    const userToChangePassword: IUserToChangePassword = {
      id: userStore.userData.id,
      newPassword: values.password.trim(),
      repeatPassword: values.confirmPassword.trim(),
    };

    try {
      await userService.changePassword(userToChangePassword);
      handleBack();
    } catch (error) {
      console.error(error);
    }
  };

  const handleBack = () => setShowNewPasswordForm(false);

  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      component='main'
      direction='column'
      className={classes.root}
    >
      <div className={classes.container}>
        <Weave className={classes.weaveRight} />
      </div>

      <Weave className={classes.weaveLeft} />

      <div className={classes.authBox}>
        <Weave className={classes.weaveInsideAuthBox} />
        <Login className={classes.login} />

        <Stack width={'32%'} margin={'24px'}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Icon icon={icons.IconLogoDark} iconSize={IconSize.Original} />
            <Typography whiteSpace={'nowrap'} className={classes.appName}>
              {t('appName')}
            </Typography>
            <Void />
            <LanguageSelector />
          </Stack>

          <Stack height={'100%'} justifyContent={'center'}>
            {showNewPasswordForm ? (
              <NewPasswordForm onSubmit={handleChangePassword} onBack={handleBack} />
            ) : (
              <AuthForm onSubmit={handleLogin} authErrorMessage={authErrorMessage} />
            )}
          </Stack>
        </Stack>
      </div>

      <Typography
        component={'div'}
        fontSize={'12px'}
        textAlign={'end'}
        lineHeight={0.5}
        color={appColors.white400}
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {!!factorVersion && (
          <p>
            {`${t('version')}: `}
            {factorVersion}
          </p>
        )}

        <p>
          {t('appCreatorInfo')} | {t('siteLink')}
        </p>
      </Typography>
    </Stack>
  );
});

export default AuthPage;
