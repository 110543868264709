import { makeAutoObservable } from 'mobx';
import { MediaDeviceDirection, MediaDevicesStoreInitProps, MediaDevice } from './types';
import { showErrorNotification } from '@/utils/notifications';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
import { cardsService } from '@/services/cards.service';
import RecordSessionConstants from '../recordSessionConstants.store';
import { BIOSCANNER_SESSION_QUERY } from '@/modules/multipass/CardCreation/components/CardModelsSection/DeviceRecording/constants';

export class MediaDevicesStore {
  isInited = false;
  componentStorageId?: string;
  allDevices?: MediaDeviceInfo[];
  allDevicesByType: Record<MediaDevice, MediaDeviceInfo[]> = { audio: [], video: [] };
  mediaRequestOptions: MediaStreamConstraints = { video: true, audio: true };
  selectedAudioInputDeviceId?: string;
  selectedVideoInputDeviceId?: string;

  isAudioDevicePermissionGranted = false;
  isVideoDevicePermissionGranted = false;

  currentRecordSessionId = ''; // айди сессии, запущенной через интерфейс приложения
  get isRecordingActive() {
    return !!this.currentRecordSessionId;
  }
  bioscannerRecordSessionId = ''; // айди сессии, запущенной биосканером (получаем из урла)
  isBioscannerRecordingActive = false;

  recordSessionConstantsStore: RecordSessionConstants;

  setCurrentRecordSessionId = (value: string) => (this.currentRecordSessionId = value);
  setBioscannerRecordSessionId = (value: string) => (this.bioscannerRecordSessionId = value);
  setMediaRequestOptions = (cons: MediaStreamConstraints) => (this.mediaRequestOptions = cons);
  setIsBioscannerRecordingActive = (value: boolean) => (this.isBioscannerRecordingActive = value);
  setIsInited = (v: boolean) => (this.isInited = v);
  setSelectedAudioInputDeviceId(value: string) {
    this.selectedAudioInputDeviceId = value;
    this.storeDefaultDeviceIds('audio', 'input', value);
  }
  setSelectedVideoInputDeviceId(value: string) {
    this.selectedVideoInputDeviceId = value;
    this.storeDefaultDeviceIds('video', 'input', value);
  }
  setIsAudioDevicePermissionGranted = (value: boolean) => {
    this.isAudioDevicePermissionGranted = value;
  };
  setIsVideoDevicePermissionGranted(value: boolean) {
    this.isVideoDevicePermissionGranted = value;
  }
  setAllDevices = (value: MediaDeviceInfo[]) => (this.allDevices = value);
  setAllDevicesByType(type: MediaDevice, value: MediaDeviceInfo[]) {
    this.allDevicesByType[type] = value;
  }

  constructor(props: MediaDevicesStoreInitProps) {
    makeAutoObservable(this, {}, { autoBind: true });

    const params = Object.fromEntries(new URLSearchParams(window.location.search));
    this.setBioscannerRecordSessionId(params[BIOSCANNER_SESSION_QUERY]);

    this.recordSessionConstantsStore = props.recordSessionConstantsStore;

    const constraints: MediaStreamConstraints = (() => {
      if (props?.constraints) return props.constraints;
      if (this.bioscannerRecordSessionId) return { video: true, audio: false };

      return this.mediaRequestOptions;
    })();
    this.setMediaRequestOptions(constraints);
  }

  async init(props?: Omit<MediaDevicesStoreInitProps, 'recordSessionConstantsStore'>) {
    props?.localStorageId && (this.componentStorageId = props.localStorageId);
    this.syncDefaultDeviceIdsWithLocalStorage();
    await this.setUserMedia();
    this.setIsInited(true);
  }

  async initBioscannerSession({ cardId }: { cardId: number }) {
    if (!this.bioscannerRecordSessionId) return false;

    const data = await cardsService.checkRecordSessionState({
      cardId,
      recordSessionId: this.bioscannerRecordSessionId,
    });

    if (!data.res) {
      this.setIsBioscannerRecordingActive(false);

      return false;
    }

    await this.recordSessionConstantsStore.initRecordingConstants();
    this.setIsBioscannerRecordingActive(true);

    return true;
  }

  async startRecording({ cardId }: { cardId: number }) {
    await this.recordSessionConstantsStore.initRecordingConstants();
    const uuid = uuidv4();
    await cardsService.createRecordSession({ cardId, recordSessionId: uuid });
    this.setCurrentRecordSessionId(uuid);
    if (this.componentStorageId) {
      localStorage.setItem(this.componentStorageId, 'true');
    }
  }

  stopRecording() {
    if (this.componentStorageId) {
      localStorage.setItem(this.componentStorageId, 'false');
    }
    this.setCurrentRecordSessionId('');
  }

  clearStorageRecordingState() {
    if (this.currentRecordSessionId) {
      this.stopRecording();
    }
  }

  storeDefaultDeviceIds(deviceType: MediaDevice, dir: MediaDeviceDirection, id?: string) {
    if (this.componentStorageId) {
      if (!id) {
        localStorage.removeItem(
          `selectedMediaDeviceId-${deviceType}-${dir}-${this.componentStorageId}`
        );

        return;
      }

      localStorage.setItem(
        `selectedMediaDeviceId-${deviceType}-${dir}-${this.componentStorageId}`,
        id
      );
    }
  }

  get selectedAudioInputDevice() {
    return (
      this.selectedAudioInputDeviceId &&
      this.allDevices?.find((device) => device.deviceId === this.selectedAudioInputDeviceId)
    );
  }

  get selectedVideoInputDevice() {
    return (
      this.selectedVideoInputDeviceId &&
      this.allDevices?.find((device) => device.deviceId === this.selectedVideoInputDeviceId)
    );
  }

  get hasDevices() {
    return !!this.allDevices?.length;
  }

  getDeviceIdFromStorage(deviceType: MediaDevice, deviceInputType: MediaDeviceDirection) {
    if (this.componentStorageId) {
      return localStorage.getItem(
        `selectedMediaDeviceId-${deviceType}-${deviceInputType}-${this.componentStorageId}`
      );
    }
  }

  syncDefaultDeviceIdsWithLocalStorage() {
    const videoInputDevice = this.getDeviceIdFromStorage('video', 'input');
    const audioInputDevice = this.getDeviceIdFromStorage('audio', 'input');
    audioInputDevice && this.setSelectedAudioInputDeviceId(audioInputDevice);
    videoInputDevice && this.setSelectedVideoInputDeviceId(videoInputDevice);
  }

  async getMediaDeviceStream(deviceType: MediaDevice, deviceId: string) {
    const data = await navigator.mediaDevices.getUserMedia({
      [deviceType]: { advanced: [{ deviceId }] },
    });

    return data;
  }

  async setUserMedia() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(this.mediaRequestOptions);
      if (this.mediaRequestOptions?.audio) {
        this.setIsAudioDevicePermissionGranted(true);
      }
      if (this.mediaRequestOptions?.video) {
        this.setIsVideoDevicePermissionGranted(true);
      }

      stream.getTracks().forEach((track) => track.stop());
    } catch (error) {
      if (this.mediaRequestOptions?.audio) {
        this.setIsAudioDevicePermissionGranted(false);
        this.setSelectedAudioInputDeviceId('');
      }
      if (this.mediaRequestOptions?.video) {
        this.setIsVideoDevicePermissionGranted(false);
        this.setSelectedVideoInputDeviceId('');
      }

      showErrorNotification({
        header: t('interactiveMode.deviceConnectError'),
        message: t('interactiveMode.deviceConnectErrorReasons'),
      });
    }

    try {
      await this.requestAndSetMediaDevices();
    } catch (error) {
      console.error(error);
    }
  }

  async requestAndSetMediaDevices() {
    let devices = await navigator.mediaDevices.enumerateDevices();

    const { allowedDevices, initConstAllowedDevices } = this.recordSessionConstantsStore;

    if (allowedDevices === undefined) {
      await initConstAllowedDevices();
    }

    if (allowedDevices) {
      devices = devices.filter((d) => allowedDevices.find((name) => d.label.includes(name)));
    }

    const devicesByType = devices.reduce(
      (acc, current, idx) => {
        if (current.deviceId) {
          acc[current.kind].push(current);
        }

        return acc;
      },
      {
        audioinput: [],
        videoinput: [],
        audiooutput: [],
      } as Record<MediaDeviceKind, MediaDeviceInfo[]>
    );

    this.setAllDevices(devices);
    this.setAllDevicesByType('audio', devicesByType.audioinput);
    this.setAllDevicesByType('video', devicesByType.videoinput);

    if (this.bioscannerRecordSessionId) {
      this.setSelectedVideoInputDeviceId(devicesByType.videoinput[0].deviceId);
    }
  }
}
