import {
  CardFileCreatedSseMessage,
  CardFileReplacedSseMessage,
  CardFilesDeletedSseMessage,
  CardFileUpdatedSseMessage,
  CardFoundInHotlistSseMessage,
  CardInteractiveAutoModeCompletedSseMessage,
  CardUpdatedSseMessage,
  DeviceChangedSseMessage,
  DeviceDeletedSseMessage,
  DeviceLogSseMessage,
  FileProcessChangedSseMessage,
  FileProcessDeletedSseMessage,
  InteractiveSessionCompletedSseMessage,
  SseMessage,
  SseMessageType,
  VadSseMessage,
} from '@/types/sse';
import { makeAutoObservable } from 'mobx';

class SseStore {
  constructor() {
    makeAutoObservable(this);
  }

  isReconnectedAfterError = false;
  currentSseConnectionId = '';
  cardUpdatedMessage: CardUpdatedSseMessage | null = null;

  cardFileCreatedMessage: CardFileCreatedSseMessage | null = null;
  cardFileUpdatedMessage: CardFileUpdatedSseMessage | null = null;
  cardFilesDeletedMessage: CardFilesDeletedSseMessage | null = null;
  cardFileReplacedMessage: CardFileReplacedSseMessage | null = null;

  cardFoundInHotlistMessage: CardFoundInHotlistSseMessage | null = null;

  vadSseMessage: VadSseMessage | null = null;
  cardInteractiveAutoModeCompletedMessage: CardInteractiveAutoModeCompletedSseMessage | null = null;
  interactiveSessionCompletedMessage: InteractiveSessionCompletedSseMessage | null = null;

  deviceChangedMessage: DeviceChangedSseMessage | null = null;
  deviceDeletedMessage: DeviceDeletedSseMessage | null = null;
  deviceLogMessage: DeviceLogSseMessage | null = null;

  fileProcessChangedMessage: FileProcessChangedSseMessage | null = null;
  fileProcessDeletedMessage: FileProcessDeletedSseMessage | null = null;

  setIsReconnectedAfterError = (value: boolean) => {
    this.isReconnectedAfterError = value;
  };

  setCardFoundInHotlistMessage = (message: CardFoundInHotlistSseMessage | null) => {
    this.cardFoundInHotlistMessage = message;
  };

  setDeviceLogMessage = (message: DeviceLogSseMessage | null) => {
    this.deviceLogMessage = message;
  };

  setCurrentSseConnectionId = (value: string) => {
    this.currentSseConnectionId = value;
  };

  setMessage = (message: SseMessage) => {
    switch (message?.type) {
      case SseMessageType.CardUpdated:
        this.cardUpdatedMessage = message;
        break;

      case SseMessageType.CardFileCreated:
        this.cardFileCreatedMessage = message;
        break;

      case SseMessageType.CardFileUpdated:
        this.cardFileUpdatedMessage = message;
        break;

      case SseMessageType.CardFilesDeleted:
        this.cardFilesDeletedMessage = message;
        break;

      case SseMessageType.CardFileReplaced:
        this.cardFileReplacedMessage = message;
        break;

      case SseMessageType.CardFoundInHotlist:
        this.setCardFoundInHotlistMessage(message);
        break;

      case SseMessageType.GUID:
        this.setCurrentSseConnectionId(message.guid);
        break;

      case SseMessageType.VAD:
        this.vadSseMessage = message;
        break;

      case SseMessageType.CardInteractiveAutoModeCompleted:
        this.cardInteractiveAutoModeCompletedMessage = message;
        break;

      case SseMessageType.DeviceChanged:
        this.deviceChangedMessage = message;
        break;

      case SseMessageType.DeviceDeleted:
        this.deviceDeletedMessage = message;
        break;

      case SseMessageType.DeviceLog:
        this.setDeviceLogMessage(message);
        break;

      case SseMessageType.FileProcessChanged:
        this.fileProcessChangedMessage = message;
        break;

      case SseMessageType.FileProcessDeleted:
        this.fileProcessDeletedMessage = message;
        break;

      case SseMessageType.InteractiveSessionCompleted:
        this.interactiveSessionCompletedMessage = message;
        break;

      default:
        break;
    }
  };
}

export default SseStore;
