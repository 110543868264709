import { createContext, useContext, PropsWithChildren } from 'react';
import { RootStore } from './root.store';

export const StoreContext = createContext<RootStore | null>(null);

const StoreProvider = ({ children }: PropsWithChildren) => {
  const store = new RootStore();

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export const useDataStore = () => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }

  return store;
};

export default StoreProvider;
