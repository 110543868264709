import { constantsService } from '@/services/constants.service';
import { CONSTANTS } from '@/types';
import { makeAutoObservable } from 'mobx';

export default class RecordSessionConstants {
  constructor() {
    makeAutoObservable(this);
  }

  frameSpeechTimeSec = 0;
  maxManualInteractivePhotosCount = 0;
  maxAutoInteractivePhotosCount = 0;
  allowedDevices: string[] | null | undefined = undefined;
  interactiveSessionTimeoutSec = 0;

  initRecordingConstants = async () => {
    const constRequests = [
      ...(!this.frameSpeechTimeSec ? [this.initConstFrameSpeechTimeSec()] : []),
      ...(!this.maxManualInteractivePhotosCount
        ? [this.initConstMaxManualInteractivePhotosCount()]
        : []),
      ...(!this.maxAutoInteractivePhotosCount
        ? [this.initConstMaxAutoInteractivePhotosCount()]
        : []),
      ...(!this.interactiveSessionTimeoutSec ? [this.initConstInteractiveSessionTimeoutSec()] : []),
    ];

    return await Promise.all(constRequests);
  };

  initConstInteractiveSessionTimeoutSec = async () => {
    const value = await constantsService.getConstantValue(
      CONSTANTS.INTERACTIVE_SESSION_TIMEOUT_SEC
    );

    if (value) {
      this.setConstInteractiveSessionTimeoutSec(value as number);

      return value as number;
    }
  };

  setConstInteractiveSessionTimeoutSec = (value: number) => {
    this.interactiveSessionTimeoutSec = value;
  };

  initConstMaxAutoInteractivePhotosCount = async () => {
    const value = await constantsService.getConstantValue(
      CONSTANTS.MAX_AUTO_INTERACTIVE_PHOTOS_COUNT
    );

    if (value) {
      this.setConstMaxAutoInteractivePhotosCount(value as number);

      return value as number;
    }
  };

  setConstMaxAutoInteractivePhotosCount = (value: number) => {
    this.maxAutoInteractivePhotosCount = value;
  };

  initConstMaxManualInteractivePhotosCount = async () => {
    const value = await constantsService.getConstantValue(
      CONSTANTS.MAX_MANUAL_INTERACTIVE_PHOTOS_COUNT
    );

    if (value) {
      this.setConstMaxManualInteractivePhotosCount(value as number);

      return value as number;
    }
  };

  setConstMaxManualInteractivePhotosCount = (value: number) => {
    this.maxManualInteractivePhotosCount = value;
  };

  initConstAllowedDevices = async () => {
    try {
      const value = await constantsService.getConstantValue<{ allowedDevices: string[] }>(
        CONSTANTS.ALLOWED_DEVICES
      );

      const result = value?.allowedDevices || null;

      this.setConstAllowedDevices(result);

      return result;
    } catch (error) {
      this.setConstAllowedDevices(null);
    }
  };

  setConstAllowedDevices = (value: string[] | null) => {
    this.allowedDevices = value;
  };

  initConstFrameSpeechTimeSec = async () => {
    const value = await constantsService.getConstantValue(CONSTANTS.FRAME_SPEECH_TIME_SEC);

    if (value) {
      this.setConstFrameSpeechTimeSec(value as number);

      return value as number;
    }
  };

  setConstFrameSpeechTimeSec = (value: number) => {
    this.frameSpeechTimeSec = value;
  };
}
