import { ICard } from '@/types/card';
import { IFileLink } from '@/types/file';
import { Device } from './device';

export enum SseMessageType {
  Ping = 'Ping',
  CardUpdated = 'CardUpdated',
  CardFileCreated = 'CardFileCreated',
  CardFileUpdated = 'CardFileUpdated',
  CardFileReplaced = 'CardFileReplaced',
  CardFilesDeleted = 'CardFilesDeleted',
  CardFoundInHotlist = 'CardFoundInHotlist',
  GUID = 'GUID',
  VAD = 'VAD', // Voice Analyze Detect
  FileProcessChanged = 'FileChanged',
  FileProcessDeleted = 'FileDeleted',
  DeviceChanged = 'DeviceChanged',
  DeviceDeleted = 'DeviceDeleted',
  DeviceLog = 'DeviceLog',
  CardInteractiveAutoModeCompleted = 'CardInteractiveAutoModeCompleted',
  InteractiveSessionCompleted = 'InteractiveSessionCompleted',
}

export type SseMessage =
  | PingSseMessage
  | CardUpdatedSseMessage
  | CardFileCreatedSseMessage
  | CardFileUpdatedSseMessage
  | CardFilesDeletedSseMessage
  | CardFileReplacedSseMessage
  | CardFoundInHotlistSseMessage
  | GuidSseMessage
  | DeviceChangedSseMessage
  | DeviceDeletedSseMessage
  | DeviceLogSseMessage
  | VadSseMessage
  | CardInteractiveAutoModeCompletedSseMessage
  | FileProcessChangedSseMessage
  | FileProcessDeletedSseMessage
  | InteractiveSessionCompletedSseMessage;

type PingSseMessage = {
  type: SseMessageType.Ping;
};

export type CardFileCreatedSseMessage = {
  cardId: number;
  type: SseMessageType.CardFileCreated;
  fileView: IFileLink;
};

export type CardFileUpdatedSseMessage = {
  cardId: number;
  type: SseMessageType.CardFileUpdated;
  fileView: IFileLink;
};

export type CardFilesDeletedSseMessage = {
  cardId: number;
  type: SseMessageType.CardFilesDeleted;
  fileLinkIds: number[];
};

export type CardFileReplacedSseMessage = {
  cardId: number;
  type: SseMessageType.CardFileReplaced;
  oldFileLinkId: number;
  newFileLinkView: IFileLink;
  recordSessionId: string;
};

export type CardUpdatedSseMessage = {
  type: SseMessageType.CardUpdated;
  card: ICard;
  fromUserDevice: boolean;
};

export type CardFoundInHotlistSseMessage = {
  type: SseMessageType.CardFoundInHotlist;
  cardId: number;
  cardAlias: string;
};

export type GuidSseMessage = {
  type: SseMessageType.GUID;
  guid: string;
};

export type VadSseMessage = {
  type: SseMessageType.VAD;
  recordSessionId: string;
  cardId: number;
  timeSpeech: number;
  requiredTimeSpeech: number;
  errorMessage: string | null;
};

export type DeviceChangedSseMessage = {
  type: SseMessageType.DeviceChanged;
  devices: Device[];
};

export type DeviceDeletedSseMessage = {
  type: SseMessageType.DeviceDeleted;
  deviceIds: number[];
};

export type DeviceLogSseMessage = {
  type: SseMessageType.DeviceLog;
  logArchiveLink: string;
  isFailed: boolean;
};

export type CardInteractiveAutoModeCompletedSseMessage = {
  type: SseMessageType.CardInteractiveAutoModeCompleted;
  recordSessionId: string;
  cardId: number;
};

export type FileProcessChangedSseMessage = {
  type: SseMessageType.FileProcessChanged;
  fileProcessId: number;
  fileProcessView: IFileLink;
};

export type FileProcessDeletedSseMessage = {
  type: SseMessageType.FileProcessDeleted;
  fileProcessId: number;
};

export type InteractiveSessionCompletedSseMessage = {
  type: SseMessageType.InteractiveSessionCompleted;
  recordSessionId: string;
  cardId: number;
};
