import { makeStyles } from '@/hooks/makeStyles';
import { appColors } from '@/Providers/ThemeProvider/colors';
import { IAuthProps } from '@/types';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, Form, PasswordField, TextField, useForm } from 'stc-ui-kit';

interface Props {
  onSubmit: (props: IAuthProps) => Promise<void>;
  authErrorMessage?: string;
}

const useStyles = makeStyles()(() => ({
  submitButton: {
    width: '100%',
    marginTop: '8px',
  },
  welcomeText: {
    fontSize: '18px',
    fontWeight: 700,
    '@media (min-width: 600px)': {
      fontSize: '24px',
    },
    '@media (min-width: 1200px)': {
      fontSize: '32px',
    },
    '@media (min-width: 1800px)': {
      fontSize: '40px',
    },
  },
  firstField: {
    '&[class*="FieldWrapper-module__fieldWrapper"]': {
      marginTop: 0,
    },
    '[class*="Label-module__label"]': {
      paddingTop: 0,
    },
  },
}));

export const AuthForm: React.FC<Props> = ({ onSubmit, authErrorMessage }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const form = useForm<IAuthProps>({
    defaultValues: {
      username: '',
      password: '',
    },
  });
  const { handleSubmit, watch, reset } = form;

  const username = watch('username');
  const password = watch('password');
  const isSubmitDisabled = !username || !password;

  const handleSubmitWithFormReset = async (data: IAuthProps) => {
    reset(data);
    await onSubmit(data);
  };

  // form.formState.isDirty is not working
  const isDirty =
    form.watch().username !== form.formState.defaultValues?.username ||
    form.watch().password !== form.formState.defaultValues?.password;

  return (
    <Form form={form} data-testid='' onSubmit={handleSubmitWithFormReset}>
      <Stack spacing={1}>
        <Typography className={classes.welcomeText} sx={{ marginBottom: '16px !important' }}>
          {t('auth.welcome')}
        </Typography>

        {!!authErrorMessage && (
          <Typography fontSize={12} color={appColors.red700} sx={{ marginTop: '0 !important' }}>
            {authErrorMessage}
          </Typography>
        )}

        <TextField
          id={'username'}
          label={t('username')}
          placeholder={t('enterUsername')}
          data-testid=''
          isRequired
          isValid={!authErrorMessage}
          className={classes.firstField}
          isFocusOnLoad
          onChange={(value) => {
            if (!value) {
              form.setValue('username', '');
            }
          }}
        />

        <PasswordField
          id={'password'}
          label={t('password')}
          placeholder={t('enterPassword')}
          data-testid=''
          isRequired
          isValid={!authErrorMessage}
          onChange={(value) => {
            if (!value) {
              form.setValue('password', '');
            }
          }}
        />

        <Button
          onClick={handleSubmit(handleSubmitWithFormReset)}
          className={classes.submitButton}
          isDisabled={isSubmitDisabled || (!!authErrorMessage && !isDirty)}
        >
          {t('auth.enter')}
        </Button>
      </Stack>
    </Form>
  );
};
