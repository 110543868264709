import { APIResponseType, METHODS, ROUTES, IApiFieldFilter } from '@/types';
import { CardFormData, CreateCardDto, EditCardDto, ICard, ICardsGridItem } from '@/types/card';
import { FaceInteractiveMode, FileGroupType, IFileLink } from '@/types/file';
import { DataProvider } from '@/types/grid';
import { EntityApi } from './entityApi.service';
import rootApiService from './root.api.service';
import { t } from 'i18next';
import { showInfoNotification } from '@/utils/notifications';
import { SseMessageType } from '@/types/sse';
import { Predicate } from '@/types/predicate';
import { StatusCodes } from 'http-status-codes';

const cardsApi = new EntityApi('card');

class CardsService {
  /**
   * изначально это нужно, чтобы при открытии карточки запрос на карточку
   * не кидался несколько раз (карточка нужна в нескольких компонентах)
   */
  loadingCardId: number | null = null;
  loadingCardPromise: Promise<APIResponseType<ICard>> | null = null;
  loadingCardPromiseResolver:
    | ((value: APIResponseType<ICard> | PromiseLike<APIResponseType<ICard>>) => void)
    | null = null;

  dataProvider: DataProvider<ICardsGridItem> = async (state, allCards?: ICardsGridItem[]) => {
    const res = await cardsApi.getFiltersData({
      filters: state,
      localData: allCards,
    });

    void this.subscribeToCardsSseEvents({
      cardIds: res.data.map((card) => card.id),
    });

    return res;
  };

  deleteCards = async (ids: number[]) => {
    return await cardsApi.deleteEntities({ ids });
  };

  private prepareCardDto = <T extends { data: CardFormData }>(cardData: T): T => {
    const newData: CardFormData = {};

    for (const key in cardData.data) {
      const dataValue = cardData.data[key];

      if (!dataValue || (Array.isArray(dataValue) && !dataValue.length)) continue;

      newData[key] = dataValue;
    }

    return { ...cardData, data: newData };
  };

  createCard = async (entity: CreateCardDto) => {
    const cardResponse = await cardsApi.createEntity<CreateCardDto, ICard>({
      entity: this.prepareCardDto(entity),
    });

    if (cardResponse.res) {
      showInfoNotification({ header: t('multipass.cardSuccessfullyCreated') });
    }

    return cardResponse;
  };

  editCard = async ({ id, entity }: { id: number; entity: EditCardDto }) => {
    const cardResponse = await cardsApi.updateEntityById<EditCardDto, ICard>({
      id,
      entity: this.prepareCardDto(entity),
    });

    if (cardResponse.res) {
      showInfoNotification({ header: t('multipass.cardSuccessfullyEdited') });
    }

    return cardResponse;
  };

  getById = async (id: number, controller?: AbortController) => {
    if (this.loadingCardId === id) {
      if (!this.loadingCardPromise) {
        this.loadingCardPromise = new Promise(
          (resolve) => (this.loadingCardPromiseResolver = resolve)
        );
      }

      return this.loadingCardPromise;
    }

    this.loadingCardId = id;

    const res = await cardsApi.getEntityById<ICard>({
      id,
    });

    if (window.location.href.includes(ROUTES.MULTIPASS_CARD_CREATION)) {
      void this.subscribeToCardFilesSseEvents({ cardId: id });
    }

    // console.log(window.location.href);

    this.loadingCardPromiseResolver?.(res);
    this.loadingCardId = null;
    this.loadingCardPromise = null;

    return res;
  };

  exportCards = async (ids: number[]) => {
    return await rootApiService.callAPI({
      path: 'card/export',
      method: METHODS.POST,
      body: ids,
    });
  };

  getMediaFileProcesses = async ({
    cardId,
    fileGroup,
    ids,
    additionalFilters,
  }: {
    cardId: number;
    fileGroup?: FileGroupType[];
    ids?: number[];
    additionalFilters?: IApiFieldFilter[];
  }) => {
    const fields: IApiFieldFilter[] = [];

    if (fileGroup) {
      fields.push({
        fieldId: 'fileGroup',
        targetValues: fileGroup.map(String),
        predicate: Predicate.EQ,
      });
    }

    if (ids && ids.length > 0) {
      fields.push({
        fieldId: 'id',
        targetValues: ids.map(String),
        predicate: Predicate.EQ,
      });
    }

    if (additionalFilters) {
      fields.push(...additionalFilters);
    }

    return await rootApiService.callAPI<IFileLink[]>({
      path: `card/${cardId}/file-links/filter`,
      method: METHODS.POST,
      body: { fields },
    });
  };

  createMediaFileProcesses = async ({
    cardId,
    fileGroup,
    file,
    recordSessionId,
    faceInteractiveMode,
  }: {
    cardId: number;
    fileGroup: FileGroupType;
    file: File;
    recordSessionId?: string;
    faceInteractiveMode?: FaceInteractiveMode;
  }) => {
    const formData = new FormData();
    if (fileGroup) {
      formData.append('fileGroup', fileGroup);
    }

    if (recordSessionId) {
      formData.append('recordSessionId', recordSessionId);
    }

    if (faceInteractiveMode) {
      formData.append('faceInteractiveMode', faceInteractiveMode);
    }

    formData.append('mediaFile', file);

    return await rootApiService.callAPI<IFileLink>({
      path: `card/${cardId}/file-links`,
      body: formData,
      ignoreContentType: true,
      method: METHODS.POST,
      shouldShowErrorNotification: (error) => error.statusCode !== StatusCodes.NOT_FOUND,
    });
  };

  setCardModel = async ({ cardId, modelId }: { cardId: number; modelId: number }) => {
    return await rootApiService.callAPI<ICard>({
      path: `card/${cardId}/model/${modelId}`,
      method: METHODS.PUT,
    });
  };

  deleteMediaFilesFromCard = async ({ cardId, ids }: { cardId: number; ids: number[] }) => {
    await rootApiService.callAPI({
      path: `card/${cardId}/file-links`,
      method: METHODS.DELETE,
      body: ids,
    });
  };

  setNoModelStatus = async ({
    cardId,
    fileProcessId,
  }: {
    cardId: number;
    fileProcessId: number;
  }) => {
    return await rootApiService.callAPI({
      path: `card/${cardId}/file-links/no-model/${fileProcessId}`,
      method: METHODS.PUT,
    });
  };

  subscribeToCardsSseEvents = async ({ cardIds }: { cardIds: number[] }) => {
    if (!rootApiService.sseStore?.currentSseConnectionId) {
      console.error('service is not initialized');

      return;
    }

    await rootApiService.callAPI({
      path: 'card/events',
      method: METHODS.PUT,
      body: {
        guid: rootApiService.sseStore.currentSseConnectionId,
        updates: [
          {
            eventType: SseMessageType.CardUpdated,
            itemIds: cardIds,
          },
          {
            eventType: SseMessageType.CardFoundInHotlist,
            itemIds: cardIds,
          },
        ],
      },
    });
  };

  subscribeToCardFilesSseEvents = async ({ cardId }: { cardId: number }) => {
    if (!rootApiService.sseStore?.currentSseConnectionId) {
      console.error('service is not initialized');

      return;
    }

    return await rootApiService.callAPI({
      path: 'card/events',
      method: METHODS.PUT,
      body: {
        guid: rootApiService.sseStore.currentSseConnectionId,
        updates: [
          {
            eventType: SseMessageType.CardUpdated,
            itemIds: [cardId],
          },
          {
            eventType: SseMessageType.CardFoundInHotlist,
            itemIds: [cardId],
          },
          {
            eventType: SseMessageType.CardFileCreated,
            itemIds: [cardId],
          },
          {
            eventType: SseMessageType.CardFileUpdated,
            itemIds: [cardId],
          },
          {
            eventType: SseMessageType.CardFilesDeleted,
            itemIds: [cardId],
          },
        ],
      },
    });
  };

  getCount = async () => await cardsApi.getCount();

  subscribeToRecordSessionSseEvents = async (recordSessionId: string, cardId: number) => {
    if (!rootApiService.sseStore?.currentSseConnectionId) {
      console.error('service is not initialized');

      return;
    }

    return await rootApiService.callAPI({
      path: 'card/session/events',
      method: METHODS.PUT,
      body: {
        guid: rootApiService.sseStore.currentSseConnectionId,
        recordSessionId: recordSessionId,
        cardId: cardId,
      },
    });
  };

  processAudioFrame = async ({
    cardId,
    file,
    recordSessionId,
  }: {
    cardId: number;
    file: File;
    recordSessionId: string;
  }) => {
    const formData = new FormData();
    formData.append('recordSessionId', recordSessionId);
    formData.append('mediaFile', file);

    return await rootApiService.callAPI<IFileLink>({
      path: `card/${cardId}/frame`,
      body: formData,
      ignoreContentType: true,
      method: METHODS.POST,
    });
  };

  createRecordSession = async ({
    cardId,
    recordSessionId,
  }: {
    cardId: number;
    recordSessionId: string;
  }) => {
    return await rootApiService.callAPI({
      path: `card/${cardId}/session?recordSessionId=${recordSessionId}`,
      ignoreContentType: true,
      method: METHODS.POST,
    });
  };

  pingRecordSession = async ({
    cardId,
    recordSessionId,
  }: {
    cardId: number;
    recordSessionId: string;
  }) => {
    return await rootApiService.callAPI({
      path: `card/${cardId}/session/ping?recordSessionId=${recordSessionId}`,
      ignoreContentType: true,
      method: METHODS.POST,
      shouldShowErrorNotification: (error) => error.statusCode !== StatusCodes.NOT_FOUND,
    });
  };

  checkRecordSessionState = async ({
    cardId,
    recordSessionId,
  }: {
    cardId: number;
    recordSessionId: string;
  }) => {
    return await rootApiService.callAPI<{
      cardId: number;
      lastActive: string;
      sessionId: string;
      timeSpeechRequired: number;
      timeSpeechTotal: number;
    }>({
      path: `card/${cardId}/session/state?recordSessionId=${recordSessionId}`,
      method: METHODS.GET,
      shouldShowErrorNotification: (error) => error.statusCode !== StatusCodes.NOT_FOUND,
    });
  };
}

export const cardsService = new CardsService();
