import { useHavePermissionsToPage } from '@/hooks/useHavePermissinonsToPage';
import { useHavePermissions } from '@/hooks/useHavePermissions';
import { useDataStore } from '@/Providers/StoreProvider';
import { PrivilegeCode } from '@/types/role';
import { observer } from 'mobx-react';
import { createContext, PropsWithChildren } from 'react';

interface ContextValues {
  havePermissions: (condition: PrivilegeCode[][]) => boolean;
  checkIsPageAllowed: (route: string) => boolean;
  defaultRoute: string | null;
  isCardTokenSession: boolean;
}

export const UserPermissionsContext = createContext<ContextValues | null>(null);

export const UserPermissionsContextProvider = observer(({ children }: PropsWithChildren) => {
  const { userStore } = useDataStore();
  const { userData } = userStore;
  const { havePermissions } = useHavePermissions(userData);
  const { checkIsPageAllowed, defaultRoute } = useHavePermissionsToPage(userData);
  const isCardTokenSession = !!userData.card_token && !userData.t4f_token;

  return (
    <UserPermissionsContext.Provider
      value={{ havePermissions, checkIsPageAllowed, defaultRoute, isCardTokenSession }}
    >
      {children}
    </UserPermissionsContext.Provider>
  );
});
