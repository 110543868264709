import { useEffect, useRef, useState } from 'react';
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';
import { useDataStore } from '@/Providers/StoreProvider';
import { makeURL } from '@/services/root.api.service';
import { SseMessage, SseMessageType } from '@/types/sse';
import { Queue } from '@/utils/Queue';

export const useInitAppSse = () => {
  const { userStore, sseStore } = useDataStore();
  const isError = useRef(false);

  /**
   * когда ссе-ивенты одного типа приходят одновременно,
   * useEffect, который следит за соответствующим сообщением в ссе сторе,
   * реагирует только на последнее. Асинхронная очередь нужна для того,
   * чтобы отдавать ивенты с разницей по времени, чтобы useEffect улавливал их все.
   */
  const asyncQueue = useRef(new Queue<SseMessage>());
  const [queueLength, setQueueLength] = useState(asyncQueue.current.length);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const dequeue = () => {
      if (asyncQueue.current.length) {
        timeout = setTimeout(() => {
          const removedNode = asyncQueue.current.dequeue();
          if (removedNode) {
            sseStore.setMessage(removedNode);
            setQueueLength(asyncQueue.current.length);
          }
          dequeue();
        }, 0);
      }
    };

    dequeue();

    return () => {
      clearTimeout(timeout);
    };
  }, [sseStore, queueLength]);

  useEffect(() => {
    const token = userStore.userData.t4f_token;
    const cardToken = userStore.userData.card_token;
    if (!token && !cardToken) return;

    const controller = new AbortController();
    const { signal } = controller;

    const initSse = async () => {
      await fetchEventSource(makeURL('events/subscribe'), {
        headers: token
          ? {
              Authorization: `Bearer ${token}`,
            }
          : { CardToken: cardToken },
        onmessage(event) {
          try {
            const parsedData: SseMessage = JSON.parse(event.data);

            if (parsedData.type !== SseMessageType.Ping) {
              asyncQueue.current.enqueue(parsedData);
              setQueueLength(asyncQueue.current.length);
              // do not delete, useful for debug
              // console.log(parsedData);
            }
          } catch (error) {
            console.error(error);
          }
        },
        signal,
        async onopen(response) {
          await new Promise<void>((res) => res());

          if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
            asyncQueue.current = new Queue();

            if (isError.current) {
              isError.current = false;
              sseStore.setIsReconnectedAfterError(true);
            }

            return;
          } else {
            console.error(
              '>>> on open error: ',
              response.status,
              response.statusText,
              response.body
            );
          }
        },
        onerror(err) {
          console.error('>>> error: ', err);
          isError.current = true;
        },
      });
    };

    void initSse();

    return () => controller.abort();
  }, [userStore.userData.t4f_token, sseStore, userStore.userData.card_token]);
};
