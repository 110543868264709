import rootApiService from './root.api.service';
import { CONSTANTS, CallAPIType, METHODS } from '@/types';

class ConstantsService {
  async getConstantValue<T>(param: CONSTANTS): Promise<T | undefined> {
    const APIOptions: CallAPIType = {
      path: `const?param=${param}`,
      method: METHODS.GET,
      body: null,
      shouldShowErrorNotification: (error) => param !== CONSTANTS.ALLOWED_DEVICES,
    };

    const apiResponse = await rootApiService.callAPI<{ value: T }>(APIOptions);

    if (apiResponse.res) {
      return apiResponse.res.value;
    }
  }

  async saveConstantValue<T>({
    isOwned,
    param,
    value,
  }: {
    param: CONSTANTS;
    value: T;
    isOwned: boolean;
  }) {
    return await rootApiService.callAPI({
      path: `const?param=${param}&isOwned=${String(isOwned)}`,
      method: METHODS.PUT,
      body: value,
    });
  }
}

export const constantsService = new ConstantsService();
