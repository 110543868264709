import rootApiService from './root.api.service';

import { CallAPIType, METHODS, SnackbarActionType, TaskType } from '@/types';
import { ApiError } from '@/utils/ApiError';
import { ITaskResponse, ICreateTaskResponse } from '@/apiTypes';

class TaskApi {
  async createNormalizeImageTask(
    photoUri: string,
    etalonModelUri: string,
    errorMessage: string,
    snackbarAction: SnackbarActionType
  ): Promise<string> {
    const tosterOptions = {
      snackbarAction,
      message: errorMessage,
    };
    const body = {
      type: TaskType.NORMALIZE_IMAGE,
      photo_uri: photoUri,
      etalon_model_uri: etalonModelUri,
    };
    const APIOptions: CallAPIType = {
      path: 'task',
      method: METHODS.POST,
      tosterOptions,
      body,
    };

    const apiResponse = await rootApiService.callAPI<ICreateTaskResponse>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res.uuid;
  }

  async createAudioTask(
    fileHash: string,
    errorMessage: string,
    snackbarAction: SnackbarActionType
  ): Promise<string> {
    const tosterOptions = {
      snackbarAction,
      message: errorMessage,
    };
    const body = {
      type: TaskType.AUTODETECT_SPEAKERS_AND_VOICE_RANGES,
      key: fileHash,
    };
    const APIOptions: CallAPIType = {
      path: 'task',
      method: METHODS.POST,
      tosterOptions,
      body,
    };
    const apiResponse = await rootApiService.callAPI<ICreateTaskResponse>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res.uuid;
  }

  async createModelAndNormalizeImageTask(
    fileHash: string,
    errorMessage: string,
    snackbarAction: SnackbarActionType
  ): Promise<string> {
    const tosterOptions = {
      snackbarAction,
      message: errorMessage,
    };
    const body = {
      type: TaskType.CREATE_MODEL_AND_NORMALIZE_IMAGE,
      key: fileHash,
    };
    const APIOptions: CallAPIType = {
      path: 'task',
      method: METHODS.POST,
      tosterOptions,
      body,
    };
    const apiResponse = await rootApiService.callAPI<ICreateTaskResponse>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res.uuid;
  }

  async compareModelsTask(
    targetModels: string[],
    etalonModels: string[],
    errorMessage: string,
    snackbarAction: SnackbarActionType
  ): Promise<string> {
    const tosterOptions = {
      snackbarAction,
      message: errorMessage,
    };
    const body = {
      type: TaskType.COMPARE_MODELS,
      target_models: targetModels,
      etalon_models: etalonModels,
    };
    const APIOptions: CallAPIType = {
      path: 'task',
      method: METHODS.POST,
      tosterOptions,
      body,
    };
    const apiResponse = await rootApiService.callAPI<ICreateTaskResponse>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res.uuid;
  }

  async getTaskResult(uuid: string): Promise<ITaskResponse> {
    const APIOptions: CallAPIType = {
      path: `task/result?uuid=${uuid}`,
      method: METHODS.GET,
    };

    const apiResponse = await rootApiService.callAPI<ITaskResponse>(APIOptions);

    if (!apiResponse.res) {
      throw new ApiError({
        httpCode: apiResponse.statusCode ?? undefined,
        errorType: apiResponse.errorDescription ?? undefined,
      });
    }

    return apiResponse.res;
  }
}

export default new TaskApi();
